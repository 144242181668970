// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
// @ts-check
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"v2025.04.08-1"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/public";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://42ddfdee192941629bd41c7dcf2ddb65@o423276.ingest.sentry.io/4504366945796096',
  // エラー検知のみに使用するため、tracesSampleRateは0にする
  tracesSampleRate: 0,
  environment:process.env.NEXT_PUBLIC_ENVIRONMENT,
  allowUrls: [/https:\/\/(stg\.)?wear\.(jp|net|tw)|http:\/\/localhost/],
  ignoreErrors:[
    "window.ethereum.selectedAddress = undefined",
    "Fetch is aborted",
    "Load failed",
    "Network Error",
    // TypeError
    "null is not an object",
    "undefined is not an object",
    "is undefined",
    "Cannot read property",
    "is not a function",
    "Cannot read properties of undefined",
    "Cannot read properties of null",
    "Properties can only be defined on Objects.",
    "Cannot redefine property",
    "WeakMap key undefined must be an object or an unregistered symbol",
    // ReferenceError
    "Can't find variable",
    "is not defined",
    // SyntaxError
    "Unexpected identifier",
    "Unexpected end of",
    "Unexpected token",
    "Unexpected EOF",
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
